import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from './ChatQuickReplies.module.scss';
import cns from 'classnames';
import { ChatButton } from './messages/ChatMessageSelected';
import { ChatStore } from '@/app/chat/chat.store';

interface ChatQuickRepliesProps {
    onClickToAction: (btn: ChatButton) => void;
    chatStore?: ChatStore;
}

@inject('chatStore')
@observer
export class ChatQuickReplies extends React.Component<ChatQuickRepliesProps> {

    onClickAction = (btn: ChatButton) => {
        this.props.onClickToAction(btn);
        this.props.chatStore!.quick_replies.replace([]);
    };

    render() {
        return <div className={cn.quickReplyList}>
            { this.props.chatStore!.quick_replies.filter((reply: any) => !!reply).map((reply: any, i: number) => {
                return <button key={i} onClick={() => this.onClickAction(reply)} className={cns(cn.quickReply, cn.quickReplyItem)} > { reply.title }</button>
            }) }
            </div>
    }
}
