import React, { FC, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { AutoComplete, Input, Flex, Popover, Button, Checkbox } from 'antd';
import { debounce } from 'lodash-es';
import { SearchItemsStore } from '@/app/components/search-items.store';
import { ProjectStore } from '@/core/stores/project.store';
import { uuid } from '@/common/utils/uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';
import { Tracker } from '@/core/analytics/tracker';
import cn from './SearchItems.module.scss';
import localforage from 'localforage';
import { CheckOutlined, FilterOutlined } from '@ant-design/icons';
import cns from 'classnames';

const Title: React.FC<Readonly<{ title?: string }>> = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
    </Flex>
);

const renderItem = ({title, id, type, text, rawText}: {
    title: string,
    id: number,
    type: string,
    text: string,
    rawText: string
}) => ({
    value: id,
    key: id?.toString() ?? '',
    type,
    result: `${title} ### ${rawText}`,
    label: (
        <Flex key={id} align="start" vertical={true}>
            <div className={cn.title}>{title}</div>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
        </Flex>
    ),
});

const renderNotFound = () => ({
    value: '',
    key: uuid(),
    label: (
        <Flex align="center" style={{color: '#909090'}} justify="space-between">
            Ничего не нашлось
        </Flex>
    ),
});

type SearchItemsComponentProps = {
    projectStore?: ProjectStore;
};

const highLightPatterns = (term: string, output: string) => {
    const escapedTerm = term.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
    return output.replace(new RegExp(escapedTerm, 'gi'), `<span style="color: #116FFC"">${term}</span>`)
}

export const SearchItemsComponent: FC<SearchItemsComponentProps> = inject('projectStore')(observer(({projectStore}) => {
    const [searchItemsStore] = useState(() => new SearchItemsStore());
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchString, setSearchString] = useState('');

    const translates: Record<string, string> = {
        'qa': t('menu.qa'),
        'intent': t('menu.intents'),
        'flow': t('menu.flows'),
    };
    const routesDict: Record<string, string> = {
        'qa': 'qa',
        'intent': 'intent',
        'flow': 'flows',
    };

    const onSearch = async (s: string) => {

        if (!s || s.length < 3) {
            setOptions([]);
            return;
        }

        setSearchString(s);

        const results = await searchItemsStore.search(projectStore.choosenProject, s, [...searchItemsStore.selectedTypes, ...searchItemsStore.selectedTypes.map((item) => `text_${item}`)], searchItemsStore.exactSearch);


        const mapped = results.reduce<Record<string, any[]>>((curr, prev) => {
            const item = {
                title: prev.name,
                id: +prev.id,
                type: prev.type,
                text: prev.text.map(t => highLightPatterns(s, t.trim())).join(', '),
                rawText: prev.text.join(',')
            };

            if (curr[prev.type]) {
                curr[prev.type].push(renderItem(item));
            } else {
                // @ts-ignore
                curr[prev.type] = [renderItem(item)];
            }

            return curr;
        }, {});

        if (!mapped['qa'] && isSelected('qa')) {
            mapped['qa'] = [];
        }

        if (!mapped['intent'] && isSelected('intent')) {
            mapped['intent'] = [];
        }

        if (!mapped['flow'] && isSelected('flow')) {
            mapped['flow'] = [];
        }


        const resultOptions = Object.keys(mapped)
            .sort((a) => {
                if (a === 'flow') {
                    return -1;
                } else if (a === 'intent') {
                    return 0;
                } else {
                    return 1;
                }
            })
            .map(key => {
                const options = mapped[key];

                if (!options.length) {
                    options.push(renderNotFound());
                }

                return {
                    label: <Title title={translates[key]}/>,
                    options
                }
            });

        setOptions(resultOptions);
    }

    const handleOpenChange = (newOpen: boolean, e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => {
        setOpen(newOpen);
        e?.stopPropagation();
    };

    const isSelected = (item: string) => {
        return searchItemsStore.selectedTypes.includes(item);
    }

    const toggle = (item: string) => {
        if (isSelected(item)) {
            const index = searchItemsStore.selectedTypes.indexOf(item);
            searchItemsStore.selectedTypes.splice(index, 1);
        } else {
            searchItemsStore.selectedTypes.push(item);
        }

        onSearch(searchString);
    }

    const [val, setVal] = useState('');

    return <div className={cn.autoCompleteWrapper}>
        <AutoComplete
            value={val}
            onChange={(v) => setVal(v)}
            onSearch={debounce(onSearch, 300)}
            onFocus={() => {
                Tracker.trackEvent('Search', { Type: 'Start' });
            }}
            onSelect={(value, obj) => {
                if (!obj.type) {
                    return;
                }

                const id = obj.key;
                const type = obj.type;
                const result = obj.result;

                Tracker.trackEvent('Search', { Type: 'Completed', SearchPhrase: searchString, SelectedPhrase: result, ObjectId: id });

                setVal('');

                if (type === 'flow') {
                    localforage.setItem('currentFlowId', id);
                } else if (type === 'intent') {
                    localforage.setItem('currentIntentId', id);
                }

                navigate(`/app/${params.projectId}/${routesDict[type]}/${id}`);
            }}
            popupMatchSelectWidth={800}
            style={{width: 350, marginBottom: '-14px'}}
            options={options}
            size="large"
        >
            <Input value={val} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} allowClear size="middle" placeholder="Поиск по сценариями, намерениям, QA"/>
        </AutoComplete>
        <Popover
            content={
                <SelectType setExactSearch={async (value) => {
                    searchItemsStore.exactSearch = value;    
                    onSearch(searchString);
                }} exactSearch={searchItemsStore.exactSearch} selected={searchItemsStore.selectedTypes}
                            isSelected={isSelected} toggle={toggle}/>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeft: 'none' }} icon={<FilterOutlined/>}></Button>
        </Popover>
    </div>

}));

type SelectTypeProps = {
    isSelected: (item: string) => boolean;
    toggle: (item: string) => void;
    selected: string[];
    setExactSearch: (value: boolean) => void;
    exactSearch: boolean;
};
const SelectType: FC<SelectTypeProps> = ({
                                             isSelected, toggle, exactSearch, setExactSearch
                                         }) => {

    return <div>
        <Checkbox value={exactSearch} onChange={(e) => {
            console.log(e.target.checked);
            setExactSearch(e.target.checked);
        }}>
            Точный поиск
        </Checkbox>
        <div className={cn.searchInTitle}>Искать в:</div>
        <div onClick={() => toggle('flow')}
             className={cns(cn.dropdownItem, isSelected('flow') && cn.dropdownItemSelected)}>
            <div>
                Сценариях
            </div>
            {isSelected('flow') && <CheckOutlined style={{color: '#1890FF'}}/>}
        </div>
        <div onClick={() => toggle('intent')}
             className={cns(cn.dropdownItem, isSelected('intent') && cn.dropdownItemSelected)}>
            <div>
                Намерениях
            </div>
            {isSelected('intent') && <CheckOutlined style={{color: '#1890FF'}}/>}
        </div>
        <div onClick={() => toggle('qa')} className={cns(cn.dropdownItem, isSelected('qa') && cn.dropdownItemSelected)}>
            <div>
                QA
            </div>
            {isSelected('qa') && <CheckOutlined style={{color: '#1890FF'}}/>}
        </div>
    </div>
}
