import { action, makeAutoObservable, observable } from 'mobx';

import { Event, EventsByDate, TDate } from '@/common/models/Event';
import { Project } from '@/common/models/project';
import { LogsApi } from '../../logs.api';
import { notification } from 'antd';
import dayjs from 'dayjs';

export class LogChatStore {
    events: Event[] = [];
    @observable eventsByDate: EventsByDate[] = [];
    fetching: boolean = false;
    isInited: boolean = false;
    hasError: boolean = false;
    page: number = 1;
    end: boolean = false;

    private static formatDate = (date: number): TDate => new Intl.DateTimeFormat('ru-RU').format(new Date(date)) as TDate;

    private static createEventsByDate = (events: Event[]): EventsByDate[] => {
        const messagesByDate: Record<TDate, Event[]> = events.reduce<Record<TDate, Event[]>>((prev, curr) => {
            const day = LogChatStore.formatDate(curr.timestamp);

            if (prev[day]) {
                prev[day].push(curr);
            } else {
                prev[day] = [curr];
            }

            return prev;
        }, {});

        return Object.keys(messagesByDate).sort((a, b) => dayjs(a).unix() - dayjs(b).unix()).map(date => ({ date: date as TDate, events: messagesByDate[date as TDate] }));
    }

    constructor(private project: Project, private userId: string) {
        makeAutoObservable(this);
    }

    @action
    changeUser(userId: string) {
        this.userId = userId;
        this.events = [];
        this.page = 1;
        this.end = false;
        return this.fetch();
    }

    @action.bound
    async fetch(): Promise<void> {
        try {
            if (this.fetching || this.end) return;
            this.fetching = true;
            const events = await LogsApi.getEvents(this.project, this.userId, {
                count: 100,
                timestamp_before: Date.now() / 1000,
                ascending: false,
                page: this.page
            });

            if (events.length === 0) {
                if (!this.isInited) {
                    this.hasError = true;
                }
                this.end = true;
            }

            this.page = this.page + 1;

            this.addEvents(events.reverse());
            this.fetching = false;
            if (!this.isInited) {
                this.isInited = true;
            }
        } catch(e) {
            this.fetching = false;
            this.events = [];
            this.eventsByDate = [];
            notification.error({
                message: 'Ошибка загрузки данных.\n' +
                    'Если повторяется, обратись в поддержку.',
                duration: 3
            });
        }
    }

    @action.bound
    private addEvents(events: Array<Event>) {
        this.events = [].concat(events, this.events);
        this.eventsByDate = LogChatStore.createEventsByDate(this.events);
    }

    @action
    markEvent(eventId: string) {
        return LogsApi.markEvent(this.project, eventId);
    }
}
