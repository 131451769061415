import React from 'react';
import cn from './ChatMessageSelected.module.scss';
import { MessageButton } from './MessageButton';

export enum ChatMessageType {
    LOCATION = 'location',
    TEXT = 'text',
    BUTTONS = 'buttons',
    CARD = 'select_button',
    AUDIO = 'audio',
    VIDEO = 'video',
    FILE = 'file',
    IMAGE = 'image'
}

export enum ChatMessageButtonType {
    TEXT = 'text',
    URL = 'url',
    INTENT = 'intent',
    SEND_LOCATION = 'send_location',
    SEND_EMAIL = 'send_email',
    SEND_PHONE = 'send_phone'
}

export interface ChatMessageParams {
    quick_replies?: ChatButton[];
    [key: string]: any;
}

export interface ChatButton { title: string, type: ChatMessageButtonType, params: any }

interface ChatMessageSelectedProps {
    params: ChatMessageParams;
    onClickToAction: (btn: ChatButton) => void;
}

export class ChatMessageSelected extends React.Component<ChatMessageSelectedProps> {
    onClickMessage = (btn: ChatButton) => () => {
        this.props.onClickToAction(btn);
    };

    render() {
        return <div className={cn.buttonGroup}>
            { this.props.params.text }
            { this.props.params.buttons.filter((btn: any) => !!btn).map((btn: any, i: number) => <MessageButton onClick={this.onClickMessage(btn)} key={i} className={cn.button}>{ btn.title }</MessageButton>) }
        </div>
    }
}
