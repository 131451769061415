import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/channel';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Form, Input } from 'antd';
import { useRequired } from '@/common/utils/form-validators';

type TelegramProps = {
    channel: Channel;
    isManageChannels: boolean;
    channelEditStore: ChannelEditStore;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Telegram: FC<TelegramProps> = ({
    channel,
    isManageChannels,
    channelEditStore,
    onFinish,
    isRequest
                                                                  }) => {

    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const required = useRequired();
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const initialValues = {
        telegram_bot_token: channel.params.telegram_bot_token,
    };

    return <Form initialValues={initialValues} form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}>
        <Form.Item name="telegram_bot_token" rules={[required]}
                   label='TOKEN'>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
}

